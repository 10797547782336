import React from 'react';
import dynamic from 'next/dynamic';

import { Book } from '../../../graphqlTypes';

const ImportantProduct = dynamic(() => import('./ImportantProduct'));
const PrePublishedProduct = dynamic(() => import('./PrePublishedProduct'));

interface PrepublishedProductSectionProps {
  category: string;
  updateNumItemsInSlider: (numBooks: number) => void;
  listPrePublications: Book[];
  listImportantProducts: Book[];
}

const PrepublishedProductSection = ({
  category,
  updateNumItemsInSlider,
  listPrePublications,
  listImportantProducts,
}: PrepublishedProductSectionProps): JSX.Element => {
  if (!listPrePublications?.length && !listImportantProducts?.length) return null;

  if (category === 'book') {
    return (
      <PrePublishedProduct
        updateNumber={updateNumItemsInSlider}
        category={category}
        listPrepublications={listPrePublications}
        listImportantProducts={listImportantProducts}
      />
    );
  }

  if (category === 'ebook') {
    return (
      <PrePublishedProduct
        listPrepublications={listPrePublications}
        listImportantProducts={listImportantProducts}
        updateNumber={updateNumItemsInSlider}
        category={category}
      />
    );
  }

  return (
    <ImportantProduct
      listImportantProducts={listImportantProducts}
      key={category}
      category={category}
    />
  );
};

export default PrepublishedProductSection;
