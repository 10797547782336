import React, { useEffect, useState } from 'react';

import Related from '../related/Related';
import { Book } from '../../../graphqlTypes';
import PrepublishedProductSection from './PrepublishedProductSection';

interface BooksBestSellersProps {
  productTypeTitle: string;
  listPrePublications: Book[];
  listImportantProducts: Book[];
  category: string;
  listBestSellers: Book[];
}

const BooksBestSellers = ({
  productTypeTitle,
  category,
  listPrePublications,
  listImportantProducts,
  listBestSellers,
}: BooksBestSellersProps): JSX.Element => {
  const [numBooks, setNumBooks] = useState(listImportantProducts?.length ? 3 : 4);

  const updateNumItemsInSlider = (numberBooks: number): void => {
    setNumBooks(numberBooks);
  };

  useEffect(() => {
    const numberBooks = listImportantProducts?.length ? 3 : 4;
    setNumBooks(numberBooks);
  }, [listImportantProducts]);

  return category === 'areaOfInterest' || !category ? null : (
    <>
      <h3 className="libros-mas-vendidos" key="libros-mas-vendidos">
        {`${productTypeTitle} más vendidos`}
      </h3>

      <div key="relatedSection" className="row relatedSection prePublished">
        {listPrePublications?.length || listImportantProducts?.length ? (
          <PrepublishedProductSection
            category={category}
            updateNumItemsInSlider={updateNumItemsInSlider}
            listPrePublications={listPrePublications}
            listImportantProducts={listImportantProducts}
          />
        ) : null}

        <Related
          numberOfBooks={numBooks}
          bestSellers
          category={category}
          listRelatedProducts={listBestSellers}
          isPageList
        />
      </div>
    </>
  );
};

export default BooksBestSellers;
