import { useEffect } from 'react';
import { useRouter } from 'next/router';

import useScrollBarPage from './useScrollbarPage';

const useSaveSectionScroll = (): void => {
  const { asPath } = useRouter();
  const { savePageAndScrollPosition } = useScrollBarPage();

  useEffect(() => {
    return (): void => {
      savePageAndScrollPosition();
    };
  }, [asPath]);
};

export default useSaveSectionScroll;
